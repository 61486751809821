import Echo from 'laravel-echo'
import { getToken } from '@/utils/auth'

window.Pusher = require('pusher-js')

export function useEcho() {
  const echo = new Echo({
    authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENPOINT,
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    encrypted: true,
    auth: {
      headers: {
        Authorization: 'Bearer ' + getToken(),
        Accept: 'application/json'
      }
    }
  })

  return {
    echo
  }
}
