import request from '@/utils/request'

export const ROOM_PER_PAGE = 10
export const MESSAGE_PER_PAGE = 20

export function getRooms(pager) {
  return request({
    url: '/chatrooms',
    method: 'get',
    params: {
      page: pager.page,
      per_page: pager.per_page
    }
  })
}

export function getRoomDetail(id) {
  return request({
    url: '/chatrooms/' + id,
    method: 'get'
  })
}

export function getHistory(chatId, pager) {
  return request({
    url: '/chatrooms/' + chatId + '/chats',
    method: 'get',
    params: {
      page: pager.page,
      per_page: pager.per_page
    }
  })
}

export function sendMessage(data) {
  return request({
    url: '/chats',
    method: 'post',
    data
  })
}

export function uploadImage(file, roomId) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'image')
  formData.append('folder_name', 'chats/' + roomId)

  return request({
    url: '/upload',
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
